"use client";
import React from 'react'

function Now() {
  return (
    <main>
      <section className="mt-5 py-4 ">
        <h1 className='text-3xl font-bold text-black dark:text-white transition-colors duration-1000'>Now</h1>
        <h2>Last Updated on January 25, 2025</h2>
      </section>

      <hr/>

      <section className="w-full py-8 px-2 lg:flex lg:flex-row gap-8 justify-between">
          <div className="text-zinc-600 dark:text-zinc-300 transition-colors duration-1000">
            <h1 className="text-2xl text-black dark:text-white">My Tech Stack</h1>
            <p>
              The new Samsung S25 line just came out and I upgraded from the S21 to the S25 Ultra for my birthday! I'm excited to use the delicious camera 
              and the new AI features. Apparently they're going to make the AI features subscription based at the end of 2025 which is a bit 
              disappointing. The reason why I have an iPad and Macbook is because I feel like they are the best for doing work on since they are so simple.
            </p>
            <ul>
              <li>Galaxy S25 Ultra</li>
              <li>Galaxy Buds 3 Pro</li>
              <li>Galaxy Watch 6 Classic</li>
              <li>iPad Pro 11in (3rd Generation)</li>
              <li>Macbook Pro M3 Pro</li>
            </ul>
          </div>
          <div className="mt-4 lg:mt-0">
            {/* <img className="max-w-[17rem] sm:max-w-sm max-h-52" src="https://pbs.twimg.com/media/FyfALthWAAEGBbL.jpg:large" alt="" /> */}
          </div>
      </section>

      <hr/>

      <section className="w-full py-8 px-2">
        <div className="lg:flex lg:flex-row gap-8 justify-between">
          <div className="text-zinc-600 dark:text-zinc-300 transition-colors duration-1000">
            <h1 className="text-2xl text-black dark:text-white">Exploring Small Businesses</h1>
            <p>
              Recently I've been doing lots of research about how different small business side hustles work. One that I'm particularly interested 
              in is car detailing. I've been practicing by detailing cars for friends and family and learning about the different tools and methods. 
              I've also looked into trash bin cleaning, where I would clean trash cans for people after the trash day. I think this would be easier to 
              get customers for and it could lead me to getting detailing customers. Another thing that I have been looking into is sticker or enamel pin 
              selling, where I create my own designs and sell the stickers or pins. I think researching these different things help me understand more parts 
              of how the businesses work and I think I can use the knowledge I get from my business classes.
            </p>
          </div>
          <div className="mt-4 lg:mt-0">
            {/* <img className="max-w-[17rem] sm:max-w-sm max-h-52" src="https://pbs.twimg.com/media/FyfALthWAAEGBbL.jpg:large" alt="" /> */}
          </div>
        </div>
      </section>

      <hr/>


      <section className="w-full py-8 px-2">
        <div className="lg:flex lg:flex-row gap-8 justify-between">
          <div className="text-zinc-600 dark:text-zinc-300 transition-colors duration-1000">
            <h1 className="text-2xl text-black dark:text-white">Staying Active</h1>
            <p>
              Last year I was able to reach my gym goal of hitting 225 pounds on bench press. This year, my goal is to be able to hold a front lever 
              for at least 5 seconds. I like calisthenics in that you can display your strength without any equipment. I also want to be able to improve my 
              muscle up so that I don't swing as much. I also like to play different sports and I'd say that I can pick them up pretty quickly. Recently I've been 
              playing a bit of pickleball because it is pretty simple and doesn't require too much physical effort. Last quarter I played intramural flag football and 
              I learned a lot about football routes. I also was able to use my agility and speed to make some delicious plays during the games.
            </p>
          </div>
          <div className="mt-4 lg:mt-0">
            {/* <img className="max-w-[17rem] sm:max-w-sm max-h-52" src="https://pbs.twimg.com/media/FyfALthWAAEGBbL.jpg:large" alt="" /> */}
          </div>
        </div>
      </section>

      <hr/>

      <section className="w-full py-8 px-2">
        <div className="lg:flex lg:flex-row gap-8 justify-between">
          <div className="text-zinc-600 dark:text-zinc-300 transition-colors duration-1000">
            <h1 className="text-2xl text-black dark:text-white">Gaming</h1>
            <p>
              The game I probably spend the most time on right now is Brawl Stars. I'm just grinding to get a lot of my brawlers to 1000 trophies, max rank. 
              My favorite game mode is heist and my favorite brawler is Brock, which is the only brawler I have maxed mastery for right now.
              Another game I have is Pokemon TCG Pocket, but I feel like there isn't too much content to play and I only get on to open my packs.
              I also have been trying out Marvel Rivals but I'm getting horrible frame drops, making it unplayable.
            </p>
          </div>
          <div className="mt-4 lg:mt-0">
            <img className="max-w-[17rem] sm:max-w-sm max-h-52" src="/images/brawl.avif" alt="picture of brock from brawl stars" />
          </div>
        </div>
      </section>

      <hr/>

      <section className="w-full py-8 px-2">
        <div className="lg:flex lg:flex-row gap-8 justify-between">
          <div className="text-zinc-600 dark:text-zinc-300 transition-colors duration-1000">
            <h1 className="text-2xl text-black dark:text-white">Cars!</h1>
            <p>
              I drive a 2024 Corolla Hatchback, which I think is pretty cool. It is a econobox so it doesn't have good power, but it handles well and is zippy on corners. 
              I like to add mods to my car such as the exhaust and cosmetic mods like the spoiler wing and sequential taillights. It helps me learn more about the car 
              and I like to do so because it's very hands on. My current dream car is the Toyota Supra, and I was sad to see that the A90 Supra was being disconutinued 
              since I liked how much power the BMW B58 engine was able to unlock with tuning and mods.
            </p>
          </div>
          <img className="mt-4 lg:mt-0 max-w-[17rem] sm:max-w-md max-h-52" src="/images/car.avif" alt="picture of my car" />
        </div>
      </section>
      
      
      
    </main>
  )
}

export default Now