import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { MdMail } from "react-icons/md";
import { RiArrowDownDoubleFill } from "react-icons/ri";
import React from 'react'
import {useState, useEffect } from 'react';

function About() {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollDown = () => {
    if (document.documentElement.scrollTop < 800) {
      document.getElementById('faq').scrollIntoView();
    // } else if (document.documentElement.scrollTop < 1500) {
    //   document.getElementById('skills').scrollIntoView();
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <main>
      <section className="min-h-screen">
        <div className="text-center mx-auto mt-10 text-md py-5 gap-y-2 leading-8 text-zinc-800 md:text-lg max-w-2xl dark:text-gray-200 transition-colors duration-1000">
          <p className="text-3xl pb-10">Hello. I'm <span className="text-pink-500"> Kevin</span>.</p>
          <p className="pb-10">I'm a student developer from San Diego, CA.</p>
          <p className="pb-10">I'm currently in my third year of studying Computer Science at UC San Diego.</p>
          <p>
            <a href="/now" className="underline underline-offset-4 text-pink-500" >What am I currently up to!?</a>
          </p>
        </div>
        <hr id="hrLine" className="w-48 h-1 mx-auto my-2 bg-gray-700 border-0 rounded md:my-10"/>
        <div className="justify-center text-2xl flex gap-16 py-3 text-pink-500">
          <div className="tooltip tooltip-bottom" data-tip="GitHub">
            <a target="_blank" rel="noreferrer" href="https://github.com/kevin-dough" aria-label="Github Profile"><AiFillGithub /></a>
          </div>
          <div className="tooltip tooltip-bottom" data-tip="LinkedIn">
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/kevintdo50/" aria-label="Linkedin Profile"><AiFillLinkedin /></a>
          </div>
          <div className="tooltip tooltip-bottom" data-tip="Email">
            <a target="_blank" rel="noreferrer" href="mailto:kevin12345do@gmail.com" aria-label="Email Link"><MdMail /></a>
          </div>
        </div>
        <hr id="hrLine" className="w-48 h-1 mx-auto my-2 bg-gray-700 border-0 rounded md:my-10"/>
        <div className="justify-center flex py-3">
          <a href="#faq" className="flex items-center text-pink-500 underline underline-offset-4"><RiArrowDownDoubleFill/><span>Personal Insights</span><RiArrowDownDoubleFill/></a>
        </div>
      </section>
      {/* <p className=" text-white fixed bottom-32 right-12">{scrollTop}</p> */}
          <IoIosArrowDropdownCircle id="scrollButton" onClick={scrollDown} className={`${scrollTop >= 800 ? "rotate-180" : ""} bottom-12 right-12 fixed text-black dark:text-white dark:hover:text-pink-500 hover:text-pink-500 hover:scale-150 delay-200 text-3xl lg:text-4xl transition ease-in-out duration-200 hidden sm:inline`}/>
      <section id="faq" className="min-h-screen pb-10">
        <div className="pt-16 text-black dark:text-white transition-colors duration-1000">
          <h1 className='text-3xl font-bold text-black dark:text-white transition-colors duration-1000'>Personal Insights</h1>

          <ul className="max-w-4xl mx-auto mt-20 divide-y bg-zinc-300 dark:bg-zinc-800 rounded-xl">
            
            <li>
                <details className="group">
                    <summary className="flex flex-row items-center gap-3 px-4 py-3 font-medium marker:content-none">
                        <IoIosArrowDropdownCircle className={`flex-none -rotate-90 group-open:rotate-0 text-black dark:text-white hover:scale-110 dark:hover:text-pink-500 hover:text-pink-500 delay-100 text-3xl transition ease-in-out duration-200`}/>
                        <span>What is your strength?</span>
                    </summary>

                    <article className="px-4 pb-4">
                        <p>
                            My strength is being creative. This allows me to see things from different perspectives.
                            Therefore, I am curious and question things, taking unique approaches to problems.
                            An example of this would be in the Successorator project. When there are no goals in the list, the 
                            app should display a message saying that there are no goals. However, this should dissapear 
                            when there are goals. My solution was to make the background color of the goal list change from transparent 
                            to white when there are goals. This way, the message is hidden when there are goals and shown when there are none.
                            This means that I did not have to make an observer to check if the goals list was empty or not since the text was 
                            in a completely different fragment.
                        </p>
                    </article>
                </details>
            </li>

            <li>
                <details className="group">
                    <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none">
                        <IoIosArrowDropdownCircle className={`flex-none -rotate-90 group-open:rotate-0 text-black dark:text-white hover:scale-110 dark:hover:text-pink-500 hover:text-pink-500 delay-100 text-3xl transition ease-in-out duration-200`}/>
                        <span>What is your weakness?</span>
                    </summary>

                    <article className="px-4 pb-4">
                        <p>
                            My weakness is being too detail-oriented. This can lead to me spending too much time on a single task. When I was creating the live 
                            audio waveform for the Vetscribe app, I was too focused on making the waveform functional. I was trying to make it be scrollable 
                            like with voice memo apps. However, this voice recorder wouldn't have the functionality to rerecord certain parts of the audio, so scrolling 
                            through the waveform would be unnecessary. The purpose of the waveform was to show the user that the audio was being recorded. In order to 
                            work through this weakness I need to look at the bigger picture and set priorities for the task at hand.
                        </p>
                    </article>
                </details>
            </li>

            <li>
                <details className="group">
                    <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none">
                        <IoIosArrowDropdownCircle className={`flex-none -rotate-90 group-open:rotate-0 text-black dark:text-white hover:scale-110 dark:hover:text-pink-500 hover:text-pink-500 delay-100 text-3xl transition ease-in-out duration-200`}/>
                        <span>What is a time you had to resolve a team conflict?</span>
                    </summary>

                    <article className="px-4 pb-4">
                        <p>
                            During the Successorator project in the class CSE 110, there was a conflict between me and my pair programming partner about 
                            splitting work and doing our fair share of work. I talked with him about it and I found out that he didn't understand how to 
                            do the code or work in Android Studio. In order to resolve this I offered to help him by providing him resources that I had used to learn 
                            such as the labs we did in class and I also guided him through the code I wrote and explained it. I think this was helpful for him because 
                            he was able to contribute to a ticket in the end.
                        </p>
                    </article>
                </details>
            </li>

            <li>
                <details className="group">
                    <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none">
                        <IoIosArrowDropdownCircle className={`flex-none -rotate-90 group-open:rotate-0 text-black dark:text-white hover:scale-110 dark:hover:text-pink-500 hover:text-pink-500 delay-100 text-3xl transition ease-in-out duration-200`}/>
                        <span>What is a time you had to learn a skill in a short period of time?</span>
                    </summary>

                    <article className="px-4 pb-4">
                        <p>
                            In the Opinions hackathon project, our group created an iOS app that also implemented OpenAI LLM API. This was very new to me since 
                            the last time I had made an iOS app was in 10th grade in high school and I haven't really used the APIs for ChatGPT. By reading documentation 
                            and asking more experienced teammates, I was able to quickly understand and get on the same page as the others. I learned basics of Swift and 
                            also how to basically use ChatGPT with python. It was important for me to try to figure it out on my own first and exhaust my online resources before 
                            I asked my teammates so that I didn't ask too many questions and distract them from their work. 
                        </p>
                    </article>
                </details>
            </li>
            

        </ul>

        </div>
      </section>
    </main>
  )
}

export default About